<template>
    <div class="box  is-hero-bar is-main-hero" v-if="model.lista != null && model.lista.length > 0" >        
        <div>
            <div class="panel">
                <div v-for="(receber, index) in model.lista" :key="index">
                    <div class="panel-block is-block portal-mes" 
                        v-if="index == 0 || new Date(receber.data).getMonth() != new Date(model.lista[index - 1].data).getMonth()">
                        <nav class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <h3 class="label">
                                        {{monthNames[new Date(receber.data).getMonth()]}} / {{new Date(receber.data).getFullYear()}}
                                    </h3>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div class="panel-block columns is-multiline is-mobile">                        
                        <div class="column is-13-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-3 is-full-mobile">
                                    <small>{{$t('FATURAS.FATURA')}}</small>
                                    <br />
                                    <b> {{receber.id}} </b>
                                </div>
                                <div class="column is-2 is-half-mobile">
                                    <small>{{$t('FATURAS.DATA')}}</small>
                                    <br />
                                    <b>{{receber.data | moment("DD/MM/YYYY") }}</b>
                                </div>
                                <div class="column is-2 is-half-mobile">
                                    <small>{{$t('FATURAS.SITUACAO')}}</small>
                                    <br />

                                    <div v-if="receber.cancelada">
                                        <b>CANCELADA</b>
                                    </div>
                                    <div v-else-if="receber.paga">
                                        <b>PAGA</b>
                                    </div>
                                    <div v-else>
                                        <b>PENDENTE</b>
                                    </div>

                                </div>

                                <div class="column is-2 is-half-mobile">
                                    <small>{{$t('FATURAS.DATADEVENCIMENTO')}}</small>
                                    <br />
                                    <b>{{ $moment(receber.dataDeVencimento).format('DD/MM/YYYY') }}</b>

                                    <div v-if="receber.parcelas.filter(x => x.situacao == 1).length">
                                        <div v-if="retornarVencimento(receber.dataDeVencimento) < 0">
                                            <b>Atrasado</b>
                                        </div>
                                        <div v-if="retornarVencimento(receber.dataDeVencimento) == 0">
                                            <b>Vence hoje.</b>
                                        </div>
                                        <div v-else-if="retornarVencimento(receber.dataDeVencimento) > 0 && retornarVencimento(receber.dataDeVencimento) <= config.diasDeAlertaVencimentoPortal">
                                            <b>Vencimento em {{ retornarVencimento(receber.dataDeVencimento) }} dias.</b>
                                        </div>
                                    </div>
                                </div>

                                <div class="column is-1 is-half-mobile">
                                    <small>{{$t('FATURAS.PARCELAS')}}</small>
                                    <br />
                                    <b>{{ receber.parcelasPagas }}/{{ receber.qtdeParcelas }}</b>
                                </div>
                   
                                <div class="column is-1 is-half-mobile mt-4">
                                    <div v-if="receber.parcelas.filter(x => x.situacao == 1).length">
                                        <div v-if="retornarVencimento(receber.dataDeVencimento) < 0">
                                            <b-icon icon="alert-octagon" size="is-large" type="is-danger"></b-icon>
                                        </div>
                                        <div v-else-if="retornarVencimento(receber.dataDeVencimento) >= 0 && retornarVencimento(receber.dataDeVencimento) <= config.diasDeAlertaVencimentoPortal">
                                            <b-icon icon="alert" size="is-large" type="is-warning"></b-icon>
                                        </div>
                                    </div>
                                </div>


                                <div class="column is-half-mobile">
                                    <small>{{$t('FATURAS.PDFSINTETICO')}}</small>
                                    <br />
                                    <b-button icon-left="eye" class="is-primary center" v-if="receber.qtdeExames > 0" @click.prevent.stop="download('ContasReceberPDFSintetico', receber.id)"></b-button>
                                </div>
                                <div class="column is-half-mobile">
                                    <small>{{$t('FATURAS.PDF')}}</small>
                                    <br />
                                    <b-button icon-left="eye" class="is-success center" v-if="receber.qtdeExames > 0"  @click.prevent.stop="download('ContasReceberPDF',receber.id)"></b-button>
                                </div>

                            </div>


                        </div>
 
                    </div>
                </div>

                <div v-if="model.lista != null" >
                    <hr>
                   <b-pagination
                        :total="total"
                        :current="page"
                        :range-before="3"
                        :range-after="1"
                        :order="''"
                        :size="''"
                        :simple="false"
                        :rounded="false"
                        :per-page="perPage"
                        :icon-prev="'chevron-left'"
                        :icon-next="'chevron-right'"
                        @change="onPageChange">
                    </b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'               

    export default {
        data() {
            return {
                total: null,
                isLoading: false,
                portal: true,
                page: 1,
                perPage: 10,
                model: [],
                monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                ],
                receberPaga: false,
            }
        },
        computed: {              
            ...mapState([
                'config',
                'usuario'
            ])
        },
        methods: {
            retornarVencimento(dataDeVencimento){
                const diferencaDias1 = this.$moment(dataDeVencimento).toDate().getTime() - new Date().getTime();
                const differenceInDays1 = Math.ceil(diferencaDias1 / (1000 * 3600 * 24));
                return differenceInDays1;
            },
            onPageChange(page) {                
                this.page = page
                this.loadListaReceber()
            },
            loadListaReceber() {
                const params = [
                    `page=${this.page}`,
                    `portal=${this.portal}`
                ]
                this.loading = true

                this.$http.get(`/api/portal/ListarReceber?${params.join('&')}`)
                    .then(({ data }) => {
                        this.model = data;

                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadListaReceber()
                        }

                        this.total = this.perPage * data.pageCount
                        this.loading = false
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            }, 
            download(itemName, item){
                window.open(`/api/financeiro/${itemName.toLowerCase()}?id=${item}`,'_blank');
            },
        },
        mounted() {
            this.loadListaReceber()
        },
    }
</script>